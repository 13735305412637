import React from 'react'
import {Navbar,NavbarBrand,NavbarToggler,Collapse,Nav,NavItem,NavLink} from 'reactstrap'
import Icon from 'react-simple-icons'

class Header extends React.Component{
     constructor(props) {
          super(props);

          this.toggle = this.toggle.bind(this);
          this.state = {
           isOpen: false
          };
     }
     toggle() {
          this.setState({
           isOpen: !this.state.isOpen
          });
     }
     render(){
          return(
               <Navbar dark expand="md" className="navbar-custom fixed-top">
               <div className="container">
                 <NavbarBrand className="text-white">Juice <span style={{color:'#ffb502'}}>&</span> Jess</NavbarBrand>
                 <NavbarToggler onClick={this.toggle} />
                 <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                         <NavItem><NavLink className="text-white" href="https://jessica-arnold.juiceplus.com/us/en" target="_blank">What is Juice Plus?</NavLink></NavItem>
                     <NavItem><NavLink className="text-muted">Follow Us:</NavLink></NavItem>
                     <NavItem><NavLink href="https://www.instagram.com/juiceandjess/" target="_blank"><Icon name='instagram' color='white' /></NavLink></NavItem>
                     <NavItem><NavLink href="https://www.facebook.com/juiceandjess/" target="_blank"><Icon name='facebook' color='white' /></NavLink></NavItem>
                     <NavItem><NavLink href="https://www.facebook.com/juiceandjess/" target="_blank"><Icon name='youtube' color='white' /></NavLink></NavItem>
                   </Nav>
                 </Collapse>
            </div>
               </Navbar>

          )
     }
}
export default Header
